'use client'

import { useEffect, useState, useRef } from 'react'

import useIntersectionObserver from '@/components/Helpers/UseIntersectionObserver/UseIntersectionObserver'

import styles from './NoticeBar.module.scss'
import classNames from 'classnames/bind'

const classes = classNames.bind(styles)

const NoticeBar = ({ display, message, countdown, navbarType }) => {
    const bannerRef = useRef(null)
    const [text, setText] = useState(countdown ? null : '')

    useEffect(() => {
        setTimeout(() => {
            setText(getCountdown(countdown))
        }, 1000)
    }, [text])
    useIntersectionObserver({
        refs: [bannerRef],
        callback: ({ isIntersecting }) => {
            if (isIntersecting) {
                bannerRef.current.classList.add(classes('enter'))
            }
        },
        options: {
            // a threshold of 0 will trigger our IntersectionObserver at the
            // base of the element – a threshold of 1 would trigger at the top.
            threshold: [0],
        },
    })

    return (
        <div
            className={classes('wrapper', {
                dark: navbarType == 'default',
                hidden: !display || (countdown && new Date(countdown) < new Date()),
            })}
            ref={bannerRef}
        >
            <span className={classes('message', { visible: !!text })}>{message}</span>
            {countdown && <CountdownText text={text} />}
        </div>
    )
}

const CountdownText = ({ text }) => {
    return <span className={classes('countdown', 'message', { visible: !!text })}>{text}</span>
}

const getCountdown = (countdown) => {
    const currentDate = new Date()
    const endDate = new Date(countdown)
    let delta = Math.abs(endDate - currentDate) / 1000

    // calculate (and subtract) whole days
    let days = Math.floor(delta / 86400)
    delta -= days * 86400

    // calculate (and subtract) whole hours
    let hours = Math.floor(delta / 3600) % 24
    delta -= hours * 3600

    // calculate (and subtract) whole minutes
    let minutes = Math.floor(delta / 60) % 60
    delta -= minutes * 60

    // what's left is seconds
    let seconds = Math.floor(delta % 60)

    return `${minTwoDigits(days)}d ${minTwoDigits(hours)}h ${minTwoDigits(minutes)}m ${minTwoDigits(seconds)}s`
}

const minTwoDigits = (n) => {
    return (n < 10 ? '0' : '') + n
}

export default NoticeBar
