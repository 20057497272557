'use client'

import Script from 'next/script'

export default () => {
    // if (process.env.NEXT_PUBLIC_ENVIRONMENT != 'prod') return

    return (
        <>
            <Script id="google-tag-consent" strategy="afterInteractive">
                {`// Define dataLayer and the gtag function.
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('set', 'url_passthrough', true);

                // Set default consent to 'denied' as a placeholder
                // Determine actual values based on your own requirements
                gtag('consent', 'default', {
                    'ad_personalization': 'denied',
                    'ad_storage': 'denied',
                    'ad_user_data': 'denied',
                    'analytics_storage': 'denied',
                    'functionality_storage': 'denied',
                    'personalization_storage': 'denied', 
                    'security_storage': 'granted' 
                });`}
            </Script>
            <Script id="google-tag-manager" strategy="afterInteractive">
                {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://analytics.taxd.co.uk/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-T5T69K4');`}
            </Script>
            <noscript>
                <iframe
                    src="https://analytics.taxd.co.uk/ns.html?id=GTM-T5T69K4"
                    height="0"
                    width="0"
                    style={{ display: 'none', visibility: 'hidden' }}
                ></iframe>
            </noscript>
        </>
    )
}
